import SpecialNodeKind from 'enums/SpecialNodeKind';
import FlowNode from 'types/FlowNode';
import { RUNTIME, REQUIREMENTS } from 'constants/EnvironmentProperty';

function getNodes(flow) {
  if (!flow.nodes) return [];

  return flow.nodes.map((node) => {
    const clonedNode = { ...node };
    if (isScriptNode(node)) {
      delete clonedNode.config;
    }
    return clonedNode;
  });
}

function getEdges(flow) {
  return flow.edges || [];
}

function getVariables(flow) {
  return flow.variables || [];
}

function getScriptCells(flow) {
  const scriptCells = {};
  if (flow.nodes) {
    flow.nodes
      .filter((node) => isScriptNode(node))
      .forEach((scriptNode) => {
        const { id, config } = scriptNode;
        scriptCells[id] = config.cells;
      });
  }
  return scriptCells;
}

function isScriptNode(node: FlowNode) {
  return node.kind === SpecialNodeKind.Script;
}

function getEnvironment(flow) {
  return (
    flow.environment || {
      runtime: RUNTIME.DEFAULT,
      requirements: REQUIREMENTS.DEFAULT,
    }
  );
}

export default {
  getNodes,
  getEdges,
  getEnvironment,
  getScriptCells,
  getVariables,
};
